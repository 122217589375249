import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import MikeWiseContact from "../components/mikewisecontactus"
import LynneMarlorContact from "../components/lynnemarlorcontactus"
import SamNathanContact from "../components/samnathancontactus"
import HeatherLoweContact from "../components/heatherlowecontactus"
import EricRouxContact from "../components/ericrouxcontactus"
import JohnHargravesContact from "../components/johnhargravescontactus"
import DougMehneContact from "../components/dougmehnecontactus"
import AJDeboleContact from "../components/ajdebolecontactus"


export default function WhoTemplate() {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false)
  const [isOverlayVisibleLM, setIsOverlayVisibleLM] = useState(false)
  const [isOverlayVisibleSN, setIsOverlayVisibleSN] = useState(false)
  const [isOverlayVisibleHL, setIsOverlayVisibleHL] = useState(false)
  const [isOverlayVisibleER, setIsOverlayVisibleER] = useState(false)
  const [isOverlayVisibleJH, setIsOverlayVisibleJH] = useState(false)
  const [isOverlayVisibleDM, setIsOverlayVisibleDM] = useState(false)
  const [isOverlayVisibleAD, setIsOverlayVisibleAD] = useState(false)

  const handleToggleOverlay = () => {
    setIsOverlayVisible(!isOverlayVisible)
  }		
  const handleToggleOverlayLM = () => {
    setIsOverlayVisibleLM(!isOverlayVisibleLM)
  }		
  const handleToggleOverlaySN = () => {
    setIsOverlayVisibleSN(!isOverlayVisibleSN)
  }		
  const handleToggleOverlayHL = () => {
    setIsOverlayVisibleHL(!isOverlayVisibleHL)
  }	
  const handleToggleOverlayER = () => {
    setIsOverlayVisibleER(!isOverlayVisibleER)
  }		
  const handleToggleOverlayJH = () => {
    setIsOverlayVisibleJH(!isOverlayVisibleJH)
  }		
  const handleToggleOverlayDM = () => {
    setIsOverlayVisibleDM(!isOverlayVisibleDM)
  }		
  const handleToggleOverlayAD = () => {
    setIsOverlayVisibleAD(!isOverlayVisibleAD)
  }		

  return (
    <Layout breadcrumbs={["Who We Are"]}>
          <div class="carousel-container">
      <div className="group-5-about-us" >
      <div className="connecting-the-blockchain-together" >About the BBA</div>
      <p className="about-us-carousel">We started in 2019 as a handful of friends sitting around a table at a WeWork in Boston. We had a big idea: Boston could be a global hub for the blockchain industry, thanks to our roots in finance, our deep tech expertise, and our world-leading academic network.<br /><br />Today we are a global community of over 3,500 blockchain enthusiasts, from institutions to startups, from developers to investors, from educators to students. What we share is a passion for how blockchain technology is changing the world.</p>
      </div> 
        <img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1703177001/o4vyera4ufkrnkbmedjg.jpg" class="group-3" />
    </div>  
    <div className="container-noflex">
      <div className="group-8">
        <h1>What We Do</h1>
      </div>
       <div className="group-8">
          <div className="who-we-are-new">
             <img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1700663765/about-us/ypwm4yqystmhjslzo7b4.png"  className="image-about-us" />
             <h3>We Educate: </h3>
             <p>Through our events, workshops, and learning sessions, we help our members and the broader community learn about the many ways in which blockchain technology can be used to solve real-market problems.</p>             
          </div>
          <div className="who-we-are-new">
             <img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1700663765/about-us/h7rrtuexwc7qhcm4jdkv.png"  className="image-about-us" />
             <h3>We Advocate: </h3>
             <p>We collaborate directly with state lawmakers to pursue balanced and informed legislation for the blockchain sector. Through education and dialogue, we help shape policies that bolster innovation while making Massachusetts a national leader in blockchain.</p>             
          </div>       
          <div className="who-we-are-new">
             <img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1700663766/about-us/bp3a9cznz08i4clq3kee.png"  className="image-about-us" />
             <h3>We Collaborate: </h3>
             <p>We provide a platform for our members to network and share ideas. Whether you&#039;re looking to find your next job opportunity, meet potential business partners, or simply meet other blockchain enthusiasts, the BBA is the perfect place for you.</p>                      
          </div>   

          <div className="who-we-are-new">
             <img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1700846471/about-us/1000_F_620972705_owWUMmfyPNp9ETVO2PUEAOLHCbhco9kM_4_1_gfpf0h.png"  className="image-about-us" />
             <h3>We Cultivate:</h3>
             <p>We connect student blockchain clubs to foster collaboration and knowledge exchange. We connect students with companies hiring for internships and job placements. And we spotlight groundbreaking research, bridging the gap between academia and the blockchain industry.</p>             
          </div>       
          <div className="who-we-are-new">
             <img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1700846505/about-us/1000_F_620972705_owWUMmfyPNp9ETVO2PUEAOLHCbhco9kM_5_1_s3csxa.png"  className="image-about-us" />
             <h3>We Celebrate:</h3>
             <p>We mark the milestones that show our progress – both our individuals, and our industry. We celebrate not just the technological achievements, but the people behind them – the diverse visionaries that make the Boston blockchain scene so special.</p>                      
          </div>                 
      </div>  
    </div>  
  
    <div className="container-noflex">
    <div className="group-8">
        <h1>Who We Are</h1>
     </div>  
    <div className="group-8">  
    <div className="who-we-are-new-people">
             <a href="https://www.linkedin.com/in/dougmehne/" target="_blank"><img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1700745229/about-us/hsnwvuwlpam31zo47qi4.png"  className="image-about-us-people" /></a>
             <h3>Doug Mehne</h3>
             <h5>Board Chair</h5>             
             <p>Doug, who joined BBA in 2021, spearheads our growth and strategy, drawing from his 20 years in the fintech sector. He envisions the BBA as a nexus for tech, innovation, and the unique Boston community, promoting shared blockchain knowledge and practices.</p>                      
    </div>     
    <div className="who-we-are-new-people">
             <a href="https://www.linkedin.com/in/shnathans/" target="_blank"><img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1700840011/about-us/1567129241302_2_pb38l3.png"  className="image-about-us-people" /></a>
             <h3>Sam  Nathans</h3>
             <h5>Board Vice-Chair</h5>
             <p>An early BBA member, Sam was pivotal in shaping the organization's virtual events. His decade-long expertise in decentralized tech guides the BBA's long-term goals, drawing inspiration from the blend of technology, philosophy, and economics.</p>                      
    </div>  
    <div className="who-we-are-new-people">
             <a href="https://www.linkedin.com/in/mikewise07/" target="_blank"><img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1700840049/about-us/1567129241302_3_phihao.png"  className="image-about-us-people" /></a>
             <h3>Mike Wise</h3>
             <h5>Head of Partnerships</h5>
             <p>Mike champions the BBA's global presence in the blockchain and Web3 spaces. Having witnessed blockchain's potential firsthand, he dedicates his extensive experience in technological transition to foster the BBA's educational mission. Mike envisions blockchain as a staple in the future business tech stack, with BBA leading the transformative wave.</p>                      
    </div>  
    <div className="who-we-are-new-people">
             <a href="https://www.linkedin.com/in/halowe/" target="_blank"><img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1700840079/about-us/1567129241302_4_1_yuzmsx.png"  className="image-about-us-people" /></a>
             <h3>Heather Lowe</h3>
             <h5>Secretary</h5>
             <p>Heather, who returned to Boston in 2022, plays a crucial role in nurturing BBA's community values. As a Senior Attorney at Gravis Law, she aids blockchain startups, emphasizing the importance of stimulating discussions within the BBA community.</p>                      
    </div>   
    <div className="who-we-are-new-people">
             <a href="https://www.linkedin.com/in/ericroux/" target="_blank"><img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1700840125/about-us/1567129241302_1_1_mdekv6.png"  className="image-about-us-people" /></a>
             <h3>Eric Roux</h3>
             <h5>Treasurer</h5>
             <p>A founding BBA member, Eric has been instrumental in the organization's trajectory. Having returned from Europe amidst the blockchain boom, he’s dedicated 15 years to leadership in non-profits, believing in blockchain's transformative institutional potential.</p>                      
    </div>     
    <div className="who-we-are-new-people">
             <a href="https://www.linkedin.com/in/johnhargrave/" target="_blank"><img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1700840127/about-us/1567129241302_2_1_li4kuz.png"  className="image-about-us-people" /></a>
             <h3>John Hargrave</h3>
             <h5>Membership Committee Chair</h5>
             <p>A founding member of the BBA, John currently helms the Membership Committee, ensuring a warm welcome and active engagement for all BBA members. He is a blockchain believer, as told in his bestselling book, “Blockchain for Everyone.”</p>                      
    </div>  
    <div className="who-we-are-new-people">
             <a href="https://www.linkedin.com/in/vijayrkesavan/" target="_blank"><img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1703769881/about-us/vijay_xt6xl6.png"  className="image-about-us-people" /></a>
             <h3>Vijay Kesavan</h3>
             <h5>Technology Committee Chair</h5>
             <p>Vijay, who joined BBA in 2021, chairs the technology committee, addressing the technology needs of BBA. He strongly believes in the financial inclusion outcomes of blockchain technology. The Boston community of fintech giants are great partners to the BBA to deliver these outcomes.</p>                      
    </div>  
    <div className="who-we-are-new-people">
             <a href="https://www.linkedin.com/in/ajdebole/" target="_blank"><img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1700840185/about-us/1567129241302_4_2_ccbjei.png"  className="image-about-us-people" /></a>
             <h3>AJ Debole</h3>
             <h5>Strategic Development Committee Chair</h5>
             <p>Since joining the BBA in 2022, AJ has been pivotal in shaping strategic initiatives, such as the Association’s technology platforms. She's a staunch believer in the power of blockchain to foster a transparent and equitable global economy.</p>                      
    </div>                    
    </div>    
    </div>    
    <div className="container-noflex">
        <div className=" who-we-are-new-volunteer-cta">
          <div className=" who-we-are-new-volunteer-content">
            <h2>Volunteering for the BBA</h2>
            <p>We are a volunteer-led organization. If you’re interested in joining the BBA as a volunteer, we have opportunities for all skill levels – Technology, Event Programming, Volunteer outreach, Marketing and Communications, Membership, Student and Academic ambassadors, and more.</p><p>Volunteering for the BBA is a great way to grow your personal network, share your expertise, and give back to the community.</p>
            <a href="/contact-us"><button className="who-we-are-new-volunteer-button">Contact Us</button></a>
          </div>
          <div className="who-we-are-new-volunteer-img">
            <img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1705621346/about-us/bba-about-page-bottom_avyn7x.jpg" />
          </div>          
         </div>  
    </div>    
      </Layout>
  )
}